.container {
    display: flex;
    flex-direction: column;
    background-color: rgb(243, 243, 243);
    height: 100%;
    padding: 1%;
    box-sizing: border-box;
    position: relative;
}

.title {
    font-family: var(--heading-font);
    padding: 1%;
    text-align: center;
}

.orderItemContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    gap: 0.3rem;
}

.preview {
    display: flex;
    padding: 3%;
    gap: 1rem;
    font-weight: 500;
    background-color: white;
    position: relative;
}

.imageContainer {
    display: flex;
    flex: 1;
    background-color: green;
    border: 1px solid #cccccc;
}

.imageContainer img {
    max-width: 100%;
}

.description {
    font-size: 1.3rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    flex: 3;
    padding: 1%;
}

.description .title {
    font-size: 1.3rem;
    font-family: var(--heading-font);
    color: black;
}

.description p {
    font-size: 1rem;
    font-family: var(--text-font);
    color: #acacac;
}

.description .price {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
    font-size: 1.3rem;
    font-family: var(--heading-font);
    color: black;
}

.removeBtn {
    width: min-content;
    height: min-content;
    padding: 4px 8px;
    border: 2px solid rgb(83, 83, 83);
    text-transform: uppercase;
    font-family: var(--heading-font);
    position: absolute;
    right: 15px;
    cursor: pointer;
    user-select: none;
}

.removeBtn:hover,
.removeBtn:active {
    opacity: 0.6;
}

.totalContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    padding: 2%;
}

.totalContainer .subtitle {
    font-family: var(--heading-font);
    color: #9b9a9a;
    font-size: 0.9rem;
    padding-left: 4%;
}

.totalContainer .total {
    font-family: var(--heading-font);
    padding-left: 4%;
    color: black;
    font-size: 1.4rem;
}

.totalContainer .amount {
    font-family: var(--heading-font);
    color: #9b9a9a;
    font-size: 0.9rem;
    display: flex;
    justify-content: flex-end;
    padding-right: 6%;
}

.totalContainer .totalAmount {
    font-family: var(--heading-font);
    color: black;
    font-size: 1.4rem;
    display: flex;
    justify-content: flex-end;
    padding-right: 6%;
}

.buttonContainer {
    grid-column: 1 / 3;
    display: flex;
    justify-content: space-around;
    padding: 1%;
}

.buttonContainer button {
    width: 40%;
    padding: 10px;
    text-transform: uppercase;
    font-family: var(--heading-font);
    font-size: 1.3rem;
}

.buttonContainer button:hover,
.buttonContainer button:active {
    opacity: 0.6;
}

.mnuBtn {
    background-color: white;
    border: 2px solid rgb(83, 83, 83);
}

.nextBtn {
    background-color: rgb(223, 9, 9);
    border: 2px solid rgb(223, 9, 9);
    color: white;
}

.noItems {
    font-weight: 500;
    font-family: var(--text-font);
    text-align: center;
    padding: 5%;
    font-size: 1.8rem;
    color: #9b9a9a;
}