.container {
    display: flex;
    min-height: 50px;
    border-top: 1px solid #cccccc;
}

.iconContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: rgb(223, 9, 9);
    cursor: pointer;
}

.iconContainer:hover,
.iconContainer:active {
    background-color: rgb(231, 231, 231);
}