.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: var(--heading-font);
    padding: 1%;
}

.container h1 {
    text-align: center;
    padding: 1%;
    user-select: none;
}

.dropDownContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.1rem;
    overflow-y: auto;
}

.preview {
    display: flex;
    padding: 3%;
    gap: 1rem
}

.imageContainer {
    display: flex;
    flex: 1;
    background-color: green;
    border: 1px solid #cccccc;
}

.imageContainer img {
    max-width: 100%;
}

.description {
    font-size: 1.3rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    flex: 3;
    padding: 1%;
}

.description .price {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
}

.footer {
    display: flex;
    padding: 5px;
    min-height: 50px;
    border-top: 1px solid #cccccc;
    justify-content: space-around;
    align-items: center;
}

.footer button {
    width: 40%;
    height: 90%;
    font-family: var(--heading-font);
    text-transform: uppercase;
    font-size: 1.2rem;
}

.footer button:hover,
.footer button:active {
    opacity: 0.6;
}

.mnuBtn {
    background-color: white;
    border: 2px solid rgb(83, 83, 83);
}

.addBtn {
    background-color: rgb(223, 9, 9);
    border: 2px solid rgb(223, 9, 9);
    color: white;
}