.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.promo {
    width: 100%;
    height: 15%;
    min-height: 100px;
    background-color: rgb(223, 9, 9);
}

.menuContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    font-family: var(--heading-font);
}

.tabs {
    display: flex;

    background-color: #e6e6e6;
    column-gap: 2px;
    font-family: var(--heading-font);
}

.tabItem {
    padding: 5px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-align: center;
    flex: 1;
    background-color: white;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
    user-select: none;
}

.active {
    color: rgb(223, 9, 9);
    border-bottom: none;
}