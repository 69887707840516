.container {
    background-color: rgba(255, 255, 255, 0.514);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    padding: 1%;
    display: flex;
    flex-direction: column;
}

.heading {
    font-weight: 500;
    font-family: var(--heading-font);
    font-size: 1.3rem;
    text-align: center;
}

.card input {
    outline: none;
    border: none;
    border-bottom: 2px solid rgb(223, 9, 9);
    padding: 10px;
    margin-top: 10px;
    font-family: var(--text-font);
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
}

.keyboard {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-self: center;
    gap: 1rem;
    padding: 5%;
}

.row {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.cntrlButtons {
    padding: 1%;
    display: flex;
    justify-content: space-around;
}

.cntrlButtons button {
    font-family: var(--heading-font);
    width: 30%;
    font-size: 1.4rem;
    border: none;
}

.cntrlButtons button:hover,
.cntrlButtons button:active {
    opacity: 0.6;
}

.hidden {
    display: none;
}