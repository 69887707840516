.container {
    background-color: rgb(39, 38, 38);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.banner {
    display: flex;
    flex-direction: column;
    height: 85%;
}



.bannerText {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bannerText h1 {
    font-family: var(--heading-font);
    font-size: 8rem;
    margin: 0;
    line-height: 7rem;
    color: rgba(255, 255, 255, 0.9);
}

.bannerText h2 {
    font-family: var(--heading-font);
    font-size: 5.9rem;
    line-height: 5rem;
    color: rgba(255, 255, 255, 0.8);
}

.bannerText h5 {
    font-family: var(--heading-font);
    font-size: 1.6rem;
    color: rgb(223, 9, 9);
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    height: 15%;
    color: rgb(223, 9, 9);
    font-family: var(--heading-font);
    background-color: white;
    cursor: pointer;
    position: relative;
}

.logoContainer {
    width: 75px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    position: absolute;
    left: 10%;
}

.logoContainer img {
    max-width: 100%;
    display: block;
}