.container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
}

.container h1 {
    text-align: center;
    padding: 2%;
    user-select: none;
}