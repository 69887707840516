.container {
    display: flex;
    background-color: white;
    position: relative;
    padding: 1%;
    gap: 1rem;
    margin-bottom: 3px;
}

.imageContainer {
    display: flex;
    flex: 1;
}

.imageContainer img {
    max-width: 100%;
}

.description {
    display: flex;
    flex-direction: column;
    flex: 4;
}

.includes {
    font-family: var(--text-font);
    color: #b8b8b8;
    font-weight: 400;
    margin-bottom: 10px;
}

.addedPrice {
    position: absolute;
    top: 50%;
    right: 20px;
    font-weight: 500;
}

.title {
    font-family: var(--text-font);
    font-weight: 500;
    margin-bottom: 3px;
}

.changeBtn {
    display: flex;
    text-transform: uppercase;
    width: 40%;
    height: 35%;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    font-size: 1.3rem;
    border: 2px solid #cccccc;
    cursor: pointer;
    user-select: none;
}

.changeBtn:hover,
.changeBtn:active {
    background-color: #cecece;
}