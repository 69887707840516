@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
}


html,
body {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 3px;
  background-color: black;
}

#root {
  width: clamp(50%, 700px, 90%);
  aspect-ratio: 9 / 16;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;

  --heading-font: 'Oswald', sans-serif;
  --text-font: 'Inter', sans-serif;
}

.App {
  width: 100%;
  height: 100%;
}