.container {
    background-color: rgb(243, 243, 243);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.container h1 {
    font-family: var(--heading-font);
    padding: 1%;
    text-align: center;
}

.card {
    font-family: var(--text-font);
    background-color: white;
    min-width: none;
    width: 80%;
    padding: 5%;
    align-self: center;
    font-weight: 500;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.card p {
    margin-bottom: 20px;
}

.small {
    font-size: 1.2rem;
}

.text {
    font-family: var(--heading-font);
    font-size: 3rem;
    text-transform: uppercase;
}

.newBtn {
    width: 150px;
    padding: 10px;
    margin: 3px;
    align-self: center;
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 1.3rem;
    background-color: rgb(223, 9, 9);
    color: white;
    border: none;
}

.newBtn:hover,
.newBtn:active {
    opacity: 0.6;
}