.container {
    display: flex;
    flex-direction: column;
    padding: 1%;
    background-color: rgb(238, 238, 238);
    position: relative;
}

.caretContainer {
    font-size: 1.3rem;
    position: absolute;
    right: 15px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.caretContainer .red {
    color: rgb(223, 9, 9);
}

.container h2 {
    padding: 5px;
    user-select: none;
}