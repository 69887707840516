.slideContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.slideContainer img {
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}