.container {
    border: 1px solid rgb(223, 9, 9);
    padding: 8px;
    margin: 3px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
    font-weight: 500;
}

.container:hover {
    background-color: rgba(223, 9, 9, 0.349);
    border-color: rgba(223, 9, 9, 0.349);
}

.container:active {
    background-color: rgba(223, 9, 9, 1);
    border-color: rgba(223, 9, 9, 1);
    color: white;
}