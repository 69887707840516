.container {
    box-sizing: border-box;
    padding: 1%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    row-gap: 0.7rem;
    column-gap: 0.7rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.productContainer {
    display: flex;
    flex-direction: column;
    padding: 3px;
    background-color: white;
    border-radius: 5px;
    border: 0.1rem solid #cccccc;
    cursor: pointer;
}

.productContainer:hover,
.productContainer:active {
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
}

.imageContainer img {
    max-width: 100%;
    object-fit: contain;
    display: block;
}

.label {
    font-weight: 500;
    text-align: center;
    user-select: none;
}