.container {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    font-family: var(--heading-font);
}

.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 0 10px;
}

.topBar {
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: rgb(223, 9, 9);
    margin-bottom: 20px;
}

.logoContainer {
    width: 20%;
    max-width: 200px;
    margin-bottom: 20px;
}

.logoContainer img {
    width: 100%;
    display: block;
}

.banner p {
    font-weight: 500;
    font-size: 1.1rem;
}

.options {
    display: flex;
    align-items: center;
    justify-content: center;
}

.optionContainer {
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin: 10px;
    border-radius: 10px;
    padding: 5px;
    background-color: rgb(243, 243, 243);
    cursor: pointer;
}

.optionContainer:hover,
.optionContainer:active {
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.25);
}

.optionContainer img {
    max-width: 80%;
    display: block;
}

.button {
    background-color: rgb(223, 9, 9);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    width: 100px;
    height: 50px;
    border-radius: 15px;
    cursor: pointer;
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
    margin: auto;
}

.button:hover,
.button:active {
    background-color: rgba(223, 9, 9, 0.75);
}